import React from "react"
import styled from "styled-components"

import { VSection } from "components/common"

const HomeLogos = ({ hat, title, subtitle, media }) => (
  <StyledHomeLogos>
    <VSection hat={hat} title={title} subtitle={subtitle} media={media} />
  </StyledHomeLogos>
)

const StyledHomeLogos = styled.div`
  .v_tgContainer {
    --tg-text-align: center;
    --section-align: center;
  }
  .v_mediaContainer {
    --v-section-media-top: var(--sp-32);
    --section-align: center;
  }
`

export default HomeLogos
