import React from "react"
import styled from "styled-components"

import { VSection, ValueProp, Grid } from "components/common"

import { matrixBackgroundStyle } from "utils"

const HomeBadges = ({ title, valueProps, backgroundImage }) => {
  const renderGrid = () => (
    <Grid
      list={valueProps}
      component={(vp) => <ValueProp className={vp.vpId} {...vp} />}
    />
  )

  return (
    <StyledHomeBadges>
      <VSection
        title={title}
        grid={renderGrid}
        backgroundImage={backgroundImage}
      />
    </StyledHomeBadges>
  )
}

const StyledHomeBadges = styled.div`
  .v_tgContainer {
    --tg-text-align: center;
    --section-align: center;
    --tg-max-width: 711px;
  }
  .v_mediaContainer {
    --section-align: center;
  }
  .v_gridContainer {
    --tg-text-align: center;
    --section-align: center;
    --vp-grid-gap: var(--sp-32);
    /* --grid-col-max: 257px; */
    --grid-col-max: 280px;

    .vp_mediaContainer {
      text-align: center;
      img {
        margin: 0 auto;
      }
    }
  }

  .v_backgroundImage {
    ${matrixBackgroundStyle};
  }

  .home_badges_documentation {
    --tg-hat-color: #48b591;
  }
  .home_badges_customer_support {
    --tg-hat-color: #8ebb7a;
  }
  .home_badges_innovation {
    --tg-hat-color: #c199bd;
  }
`

export default HomeBadges
