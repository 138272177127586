import React from "react"
import { graphql } from "gatsby"
// import styled from "styled-components"

import { useContentfulSection, useMeta } from "utils"

import { Layout, Seo } from "components/common"

import { PrimaryNav, MobileNav } from "src/components/navigation"

import {
  HomeHero,
  HomeQuote,
  HomeBadges,
  HomeApps,
  HomeComparison,
} from "src/sections/home"

const HomePage = ({ data: { home } }) => {
  const meta = useMeta(home)
  const {
    home_hero,
    home_quote,

    home_badges,
    home_apps,
    home_comparison,
  } = useContentfulSection(home.sections)

  return (
    <Layout>
      <Seo {...meta} />

      <MobileNav />
      <PrimaryNav />

      <HomeHero {...home_hero} />
      <HomeQuote {...home_quote} />
      {/* <HomeLogos {...home_logos} /> */}
      <HomeBadges {...home_badges} />
      <HomeApps {...home_apps} />
      <HomeComparison {...home_comparison} />
    </Layout>
  )
}

export const query = graphql`
  {
    home: contentfulPage(pageId: { eq: "home" }) {
      ...Page
    }
  }
`

export default HomePage
