import React from "react"
import styled from "styled-components"

import { VSection } from "components/common"

import { gradientText } from "utils"

const HomeQuote = ({ hatIcon, title, subtitle }) => (
  <StyledHomeQuote>
    <VSection
      hatIcon={{ desktop: hatIcon }}
      title={title}
      titleAs="h3"
      subtitle={subtitle}
      subtitleAs="h4"
    />
  </StyledHomeQuote>
)

const StyledHomeQuote = styled.div`
  .hatIconWrap {
    img {
      margin: 0 auto;
    }
  }

  .v_tgContainer {
    --tg-text-align: center;
    --tg-max-width: 735px;
    --tg-grid-gap: var(--sp-32);
    --section-align: center;

    .title {
      ${gradientText("var(--text-gradient)")};
    }

    .subtitle {
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
      line-height: 1;
      .italic {
        color: #66676d;
        margin-top: var(--sp-12);
      }
    }
  }
`

export default HomeQuote
