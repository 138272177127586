import React from "react"
import styled from "styled-components"
import map from "lodash/map"
import { VSection, Grid, AppCard, TextGroup, Media } from "components/common"

import { useContentfulComponent, device } from "utils"

const HomeHero = ({ title, valueProps, backgroundImage, components }) => {
  const { home_hero_subtitle_and_rating } = useContentfulComponent(components)

  const renderGrid = () => (
    <div className="home_grid_wrap">
      <ul>
        {map(valueProps, (vp) => (
          <AppCard
            key={vp.id}
            vp={vp}
            app={vp.to ? vp.to.replace("/", "") : "disabled"}
          />
        ))}
      </ul>
      {/* <Media className="mobileBackgroundImage" media={backgroundImage} /> */}
    </div>
  )

  return (
    <StyledHomeHero>
      <VSection
        title={title}
        titleAs="h1"
        backgroundImage={backgroundImage}
        critical
        grid={renderGrid}
        custom={() => <Subtitle {...home_hero_subtitle_and_rating} />}
      />
    </StyledHomeHero>
  )
}

const StyledHomeHero = styled.div`
  /* position: relative; */
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-padding-top: var(--sp-nav);
  --section-mobile-padding-bottom: 0;

  .v_tgContainer {
    --tg-text-align: center;
    --tg-max-width: 650px;
    --section-align: center;
  }

  .v_customContainer {
    --section-order: 2;
    --section-mobile-order: 2;
  }

  .home_grid_wrap {
    position: relative;
    padding-top: 64px;
    padding-bottom: 64px;
    grid-gap: var(--sp-80);
    @media ${device.laptop} {
      padding-bottom: 0;
    }

    ul {
      z-index: 2;
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      grid-gap: var(--sp-80);

      @media ${device.laptop} {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        grid-gap: var(--sp-80) var(--sp-32);
      }

      li {
        width: 100%;
        @media ${device.laptop} {
          /* margin: var(--sp-40) var(--sp-16); */
          max-width: 270px;
        }
        .basicCard {
          width: 100%;
          @media ${device.laptop} {
            /* min-height: 250px; */
          }
        }
      }
    }

    /* .mobileBackgroundImage {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      img {
        height: 100%;
        width: 100%;
      }

      @media ${device.laptop} {
        display: none;
      }
    } */
  } // end of grid container

  .v_backgroundImage {
    /* top: 0; */
    left: 0;
    width: 100%;
    text-align: center;
    bottom: 160px;
    display: none;

    @media ${device.laptop} {
      display: block;
    }

    img {
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
    }
  }
`

const Subtitle = ({ icon, subtitle, valueProps }) => {
  return (
    <StyledSubtitle>
      <Grid
        list={valueProps}
        component={(vp) => (
          <li>
            <TextGroup subtitle={vp.title} titleAs="p" />
          </li>
        )}
      />
      <div className="rating">
        <Media media={{ desktop: icon }} />
        <TextGroup subtitle={subtitle} subtitleAs="pSmall" />
      </div>
    </StyledSubtitle>
  )
}

const StyledSubtitle = styled.div`
  margin-top: var(--sp-12);
  ul {
    /* list-style-type: disc; */

    /* --grid-align: center; */
    /* grid-template-columns: repeat(2, auto); */
    /* --mobile-grid-gap: var(--sp-8) var(--sp-16); */
    --mobile-grid-gap: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    @media ${device.laptop} {
      grid-template-columns: repeat(3, auto);
    }

    li {
      display: flex;
      align-items: center;

      &:nth-child(2) {
        margin-left: var(--sp-16);

        @media ${device.laptop} {
          margin-left: 0;
        }
      }

      /* padding: 8px; */
      &::before {
        /* add the new bullet point */
        content: "";
        border-radius: 50%;
        height: 5px;
        width: 5px;
        margin-right: 5px;
        background-color: var(--tg-subtitle-color);
      }
    }
  }

  .rating {
    display: flex;
    align-items: center;
    justify-content: center;
    --tg-grid-gap: 0;
    margin-top: var(--sp-32);

    flex-direction: column;
    @media ${device.laptop} {
      flex-direction: row;
    }

    .subtitle {
      text-align: center;
      @media ${device.laptop} {
        text-align: left;
        margin-left: var(--sp-8);
      }
    }
  }
`

export default HomeHero
