import React from "react"
import styled from "styled-components"

import { VSection } from "components/common"

const HomeComparison = ({ media }) => (
  <StyledHomeComparison>
    <VSection media={media} />
  </StyledHomeComparison>
)

const StyledHomeComparison = styled.div`
  --section-mobile-padding-top: 0;
  --section-padding-top: 0;
  .v_tgContainer {
    --tg-text-align: center;
    --section-align: center;
  }
  .v_mediaContainer {
    --section-align: center;
  }
`

export default HomeComparison
