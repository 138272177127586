import React, { useState, useEffect } from "react"
import styled from "styled-components"

// import { Link } from "components/common"
import { device } from "utils"

import a5 from "src/images/nav/A5App_HeaderBar_Logo.png"

const HomeBanner = () => {
  const [showBanner, setShowBanner] = useState(false)
  useEffect(() => {
    const bannerState = window.localStorage.getItem("banner")
    if (bannerState === null) {
      setShowBanner(true)
    }
  }, [])
  const handleClose = () => {
    setShowBanner(false)
    window.localStorage.setItem("banner", false)
  }

  return (
    showBanner && (
      <StyledHomeBanner>
        <img src={a5} alt="a5 logo" />
        <p>
          words here
          <a href="google.com"> Read the press release to learn more</a>.
        </p>
        <button className="close" onClick={handleClose}>
          &#x2715;
        </button>
      </StyledHomeBanner>
    )
  )
}

const StyledHomeBanner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--sp-12);
  background: var(--highlight_1);
  z-index: 3;

  @media ${device.laptop} {
    padding: var(--sp-16);
  }

  img {
    width: 100%;
    max-width: 92px;
  }

  p,
  a {
    color: var(--card-shade_1);
    font-size: 0.75rem;
    line-height: 18px;
  }

  p {
    margin-left: var(--sp-16);
    max-width: 140px;
    display: flex;
    flex-direction: column;

    @media ${device.laptop} {
      display: block;

      max-width: 100%;
    }
  }

  a {
    font-weight: 600;
    /* text-decoration: underline; */

    /* @media ${device.laptop} {
      margin-left: 4px;
    } */
  }

  .close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 24px;
    font-size: 1.5rem;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`

export default HomeBanner
