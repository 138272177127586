import React from "react"
import styled from "styled-components"

import { VSection, FeatureCard, Grid } from "components/common"

const HomeApps = ({ valueProps }) => {
  const renderGrid = () => (
    <Grid list={valueProps} component={(vp) => <FeatureCard {...vp} />} />
  )

  return (
    <StyledHomeApps>
      <VSection grid={renderGrid} />
    </StyledHomeApps>
  )
}

const StyledHomeApps = styled.div`
  .v_gridContainer {
    --grid-col: 1fr;
    --grid-gap: var(--sp-64);
    --mobile-grid-gap: var(--sp-64);
  }

  .text_container {
    .cta-details {
      strong {
        font-size: 1.75rem;
        font-weight: 700;
      }
    }
    .cta {
      margin-top: var(--sp-16);
    }
  }

  .home_apps_documents {
    .text_container {
      .title strong {
        color: var(--blueDark);
      }

      --button-background: var(--blueDark);
    }
    .cta-details {
      strong {
        color: var(--blueDark);
      }
    }
  }
  .home_apps_fax {
    .text_container {
      .title strong {
        color: var(--greenDark);
      }

      --button-background: var(--greenDark);
    }
    .cta-details {
      strong {
        color: var(--greenDark);
      }
    }
  }
  .home_apps_signature {
    .text_container {
      .title strong {
        color: var(--redDark);
      }

      --button-background: var(--redDark);
    }
    .cta-details {
      strong {
        color: var(--redDark);
      }
    }
  }
  .home_apps_ship {
    .text_container {
      .title strong {
        color: var(--purpleDark);
      }

      --button-background: var(--purpleDark);
    }
    .cta-details {
      strong {
        color: var(--purpleDark);
      }
    }
  }
  .home_apps_payments {
    .text_container {
      .title strong {
        color: var(--tealDark);
      }

      --button-background: var(--tealDark);
    }
    .cta-details {
      strong {
        color: var(--tealDark);
      }
    }
  }
  .home_apps_forms {
    .text_container {
      .title strong {
        color: var(--yellowDark);
      }

      --button-background: var(--yellowDark);
    }
    .cta-details {
      strong {
        color: var(--yellowDark);
      }
    }
  }
`

export default HomeApps
